'use client';

import BingoLogo from '@/components/exam/ExamHomeView/ExamFooter/Logo';
import { useMutation } from '@apollo/client';
import { useModal } from '@flyer/components';
import { useAtom, useSetAtom } from 'jotai';
import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { filterCambridgeAtom, testLevelsByTag } from '@/store/home';
import { useAuth } from '@/providers/auth';
import { DinRoundProFont } from '@/lib/utils';
import NoAccessModal from '@/components/popups/NoAccessModal';
import { dataCambridge } from '@/components/exam/ExamHomeView/dataMock';
import { Button, DropDown } from '@/components/common';
import NewVi from '@/assets/svg/new_vi.svg';
import NewEn from '@/assets/svg/new_en.svg';
import { graphql } from '@/__generated__';
import ButtonActiveAccount from '@/components/exam/ButtonActiveAccount';
import logo from '@/assets/images/logo.png';
import MotionImage from '@/components/common/MotionImage';
import arrowDown from '../assets/arrow-down.png';
import chatBox from '../assets/chat-box.png';
import Eng from '../assets/Eng.png';
import iconTarget from '../assets/icon_target.png';
import levelFilter from '../assets/level-filter.png';
import mapIcon from '../assets/map.png';
import partnerIcon from '../assets/partner.png';
import Vie from '../assets/Vie.png';
import ChooseRoleModal from '../ChooseRoleModal';
import AnimateText from './AnimateText';
import { m } from 'framer-motion';

const UpdateLanguageMutation = graphql(`
  mutation LearnerProfileFormViewUpdateLearnerProfileMutation(
    $input: ViewerUpdateLearnerProfileInput!
  ) {
    viewerUpdateLearnerProfile(input: $input) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);

export default function ExamFooter() {
  const { t, lang } = useTranslation('common');
  const router = useRouter();
  const { currentLearnerProfile } = useAuth();
  const [filterCambridge, setFilterCambridge] = useAtom(filterCambridgeAtom);
  const setTag = useSetAtom(testLevelsByTag);

  const [, setQ] = useQueryParams({
    modalPlacementTestResult: BooleanParam,
    modalPlacementTest: BooleanParam,
    placementTestId: StringParam,
  });

  const { toggleModal: toggleNoAccess } = useModal(NoAccessModal);
  const { toggleModal: toggleChoosePartnerRole } = useModal(ChooseRoleModal);

  const [updateProfile] = useMutation(UpdateLanguageMutation, {
    context: { v2: true },
    onError() {},
  });

  const newDataCambridge = useMemo(
    () =>
      dataCambridge.map((el) => ({
        ...el,
        title: el.title.includes('all') ? t('exam.all') : el.title,
        key: el.title,
        isActive: el.title === filterCambridge,
      })),
    [filterCambridge, t],
  );

  const handlerClickPlacementTest = useCallback(() => {
    if (
      !currentLearnerProfile &&
      typeof window !== 'undefined' &&
      window.location.hostname.includes('gen.flyer.us')
    ) {
      return setQ({
        modalPlacementTest: true,
      });
    }
    if (!currentLearnerProfile) {
      toggleNoAccess();
    } else if (currentLearnerProfile?.lastPlacementTest?.userAttemptId) {
      void setQ({
        modalPlacementTestResult: true,
        placementTestId: currentLearnerProfile?.lastPlacementTest?.userAttemptId,
      });
    } else {
      void setQ({ modalPlacementTest: true });
    }
  }, [currentLearnerProfile, setQ, toggleNoAccess]);

  const handlerChangeLanguage = useCallback(() => {
    if (currentLearnerProfile) {
      void updateProfile({
        variables: {
          input: {
            id: currentLearnerProfile?.id,
            lang: lang === 'vi' ? 'en' : 'vi',
          },
        },
      });
    }
    return setLanguage(lang === 'vi' ? 'en' : 'vi');
  }, [currentLearnerProfile, lang, updateProfile]);

  const handleFilterCambridge = useCallback(
    (val: { id: string; title: string; key?: string }) => {
      if (val.key) {
        setTag(val.id);
        setFilterCambridge(val.key);
      }
    },
    [setFilterCambridge, setTag],
  );

  const handlerClickMistakes = useCallback(() => {
    void router.push('/practice-words');
  }, [router]);

  const dataFooterLeft = useMemo(() => {
    return [
      {
        title: t('exam.your_level'),
        onClick: handlerClickPlacementTest,
        imageIcon: mapIcon,
        showNew: false,
      },
      {
        title: t('exam.mistakes'),
        onClick: handlerClickMistakes,
        imageIcon: iconTarget,
        showNew: true,
      },
    ];
  }, [handlerClickMistakes, handlerClickPlacementTest, t]);

  const isSelectAllAndPre =
    (filterCambridge.includes('all') || filterCambridge.includes('pre')) && lang === 'vi';

  return (
    <>
      <div className="hidden-custom fixed bottom-[-24px]  left-[50%] [transform:translate(-50%,-50%)] h-[55px] w-[calc(100%-3vw)] xl:w-[1112px]  justify-between sm:flex flex-row xl:min-h-[77px] lg:h-[77px] home-bottom-bg items-center z-10 px-2">
        <div className="home-footer-left flex flex-row items-center">
          <DropDown
            items={newDataCambridge}
            onChange={handleFilterCambridge}
            dropdownTextItemClassName="sm:text-[4px] md:text-[5px]"
            dropdownClassName="!w-[80px] !bottom-[67px] !right-[-22px]"
            activator={
              <div className="lg:w-[57px] w-[45px] cursor-pointer relative">
                <Image src={levelFilter} alt="flyer filter" className="w-full" />
                <div className="absolute bottom-[-3px] text-center lg:w-[57px] w-[45px] flex flex-col">
                  <div className="flex flex-row items-center gap-1 justify-center">
                    <span
                      className={twMerge(
                        'text-white text-center flex justify-center text-[6px] lg:text-[11px] font-extrabold uppercase ',
                        isSelectAllAndPre ? '' : 'pl-[18px] pr-[2px]',
                      )}
                    >
                      {filterCambridge.includes('all') ? t('exam.all') : filterCambridge}
                    </span>
                    <Image src={arrowDown} alt="arrow" className="w-[9px] h-[9px]" />
                  </div>
                  <span
                    className="text-white font-extrabold text-[8px] lg:text-[11.2px] [-webkit-text-stroke:0.5px_black]"
                    style={{
                      textShadow: '0px 1px 0px #000',
                    }}
                  >
                    {t('exam.level')}
                  </span>
                </div>
              </div>
            }
            direction="up"
          />
          <div className="flex flex-row items-center justify-center gap-[2vw] pl-[2vw] xl:pl-8 xl:gap-8">
            {dataFooterLeft.map((left, index) => (
              <Button
                key={left.title}
                icon={left.imageIcon}
                colorScheme="red"
                onClick={left.onClick}
                classNameText="flex items-center justify-center pl-[2vw] text-[1vw] xl:text-[11px] xl:pl-[20px] uppercase"
                classNameIcon="w-[4vw] left-[-0.9vw] xl:w-[56px] xl:top-[-14px] xl:left-[-21px]"
                className={twMerge(
                  'w-[15vw] h-[3vw] xl:h-[32px]',
                  index === 1 ? 'xl:w-[135px]' : 'xl:w-[180px]',
                )}
              >
                {left.title}
                {left.showNew && (
                  <div className="absolute z-10 top-[-1vw] right-[-1vw] xl:top-[-11px] xl:right-[-9px]">
                    {lang === 'vi' ? (
                      <NewVi className="xl:w-[50px] w-[5vw]" />
                    ) : (
                      <NewEn className="xl:w-[50px] w-[5vw]" />
                    )}
                  </div>
                )}
              </Button>
            ))}
          </div>
        </div>
        <div className="home-footer-center relative pb-[2vw] xl:pb-[41px]">
          {/*<RiveComponent className="w-full h-full object-cover" />*/}

          <Link
            href={currentLearnerProfile ? '/real-time-conversation' : '/login'}
            className="logo-bottom flex justify-center"
          >
            {/*<MotionImage*/}
            {/*  src={logo}*/}
            {/*  alt="flyer"*/}
            {/*  className="xl:w-[94px] w-[8vw]"*/}
            {/*  whileTap={{ scale: 0.9 }}*/}
            {/*/>*/}
            <m.div
              whileTap={{ scale: 0.9 }}
              className="xl:w-[94px] w-[8vw] xl:h-[140px] h-[13vw] max-sm:hidden"
            >
              <BingoLogo />
            </m.div>

            <div
              aria-hidden
              className={twMerge(
                '!w-[221px] h-[49px] absolute left-[80%] -top-[14%] flex justify-center items-center cursor-pointer',
                'max-xl:scale-90 max-xl:left-[70%] max-xl:-top-[10%] max-lg:left-[64%] max-lg:scale-80 max-lg:-top-[17%]',
                'max-md:left-[30%] max-md:-top-[40%] max-md:scale-[0.7]',
              )}
            >
              <Image src={chatBox} alt="chatBox" width={220} className="w-full h-full" />
              <p
                className={twMerge(
                  'text-[13px] text-[#6D1715] absolute ml-5 mr-1',
                  DinRoundProFont.className,
                )}
              >
                <AnimateText text={t('real_time_conversation.bingo_hello')} delay={4001} />
              </p>
            </div>
          </Link>
        </div>
        <div className="home-footer-right flex flex-row items-center gap-9">
          <ButtonActiveAccount />
          <div className="flex flex-row items-center">
            <Button
              icon={partnerIcon}
              classNameText="flex items-center justify-center pl-[2vw] text-[1vw] xl:text-[11px] xl:pl-[20px] uppercase"
              classNameIcon="w-[4vw] left-[-0.9vw] xl:w-[56px] xl:top-[-12px] xl:left-[-21px]"
              className="w-[17vw] h-[3vw] xl:h-[32px] xl:w-[180px]"
              onClick={toggleChoosePartnerRole}
            >
              {t('exam.partner_dashboard')}
            </Button>
            <div
              aria-hidden
              className="language sm:w-[20px] sm:h-[20px]  lg:w-[40px] lg:h-[40px] 2xl:w-[50px] 2xl:h-[50px] ml-[8px] mr-[10px] xl:mr-0 cursor-pointer"
              onClick={handlerChangeLanguage}
            >
              <Image src={lang === 'en' ? Eng : Vie} alt="flyer" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex sm:hidden w-full max-w-[319px] home-bottom-bg items-center gap-[24px] mb-[20px] z-[1] p-[10px] fixed bottom-0 h-[54px] left-[50%] [transform:translate(-50%,-50%)]">
        <div className="flex flex-col justify-center items-center -mt-8">
          <DropDown
            items={newDataCambridge}
            onChange={handleFilterCambridge}
            dropdownTextItemClassName="sm:text-[4px] md:text-[5px]"
            dropdownClassName="!w-[80px] !bottom-[30px] !z-[10]"
            activator={
              <div className="w-[57px] cursor-pointer relative">
                <Image src={levelFilter} alt="flyer filter" className="w-full" />
                <div className="absolute bottom-[14px] text-center w-[57px] flex flex-col">
                  <div className="flex flex-row items-center gap-1 justify-center">
                    <span
                      className={twMerge(
                        'text-white text-center flex justify-center text-[11px] font-extrabold uppercase',
                        isSelectAllAndPre ? '' : 'pl-[18px] pr-[2px]',
                      )}
                    >
                      {filterCambridge.includes('all') ? t('exam.all') : filterCambridge}
                    </span>
                    <Image src={arrowDown} alt="arrow" className="w-[9px] h-[9px]" />
                  </div>
                </div>
              </div>
            }
            direction="up"
          />
          <span className="text-white font-extrabold text-[10px]">{t('exam.level')}</span>
        </div>

        <div className="flex flex-row items-center gap-[24px] -mt-6">
          {dataFooterLeft.map((ft) => {
            const keymap = `footer-mobile-${ft.title}`;
            return (
              <div
                key={keymap}
                aria-hidden
                onClick={ft.onClick}
                className="flex flex-col justify-center items-center gap-[6px]"
              >
                <Image src={ft.imageIcon} alt="flyer" className="w-[48px]" />
                <span className="text-white text-[10px] font-extrabold uppercase">{ft.title}</span>
              </div>
            );
          })}
        </div>
        <Link
          href={currentLearnerProfile ? '/real-time-conversation' : '/login'}
          className="logo-ft-mobile absolute w-[53px] top-[-30px] right-[-20px] z-[10] sm:hidden"
        >
          <div className="relative w-fit h-fit">
            <Image src={logo} alt="flyer" />
          </div>
          {/* <m.div whileTap={{ scale: 0.9 }} className="w-[53px] h-[90px]">
            <BingoLogo />
          </m.div> */}
        </Link>
      </div>
    </>
  );
}
