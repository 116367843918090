import { useRive } from '@rive-app/react-canvas';
import React from 'react';

export default function BingoLogo({ className }: { className?: string }) {
  const { RiveComponent } = useRive({
    src: '/animations/flyer.riv',
    autoplay: true,
    // layout: new Layout({
    //   fit: Fit.FitWidth,
    //   alignment: Alignment.Center,
    // }),
  });

  return <RiveComponent className={className} />;

  //   return (
  //     <div className="w-[94px] h-[140px] relative justify-center items-center flex">
  //       <RiveComponent className="w-[940px] h-[1400px] object-contain absolute scale-[0.1]" />
  //     </div>
  //   );
}
